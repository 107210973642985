import { useFetchCatResources } from "../../hooks/ResourceHooks";

import Header from "../../main/frame/Header";
import Resource from "../resources/Resource";
import Quote from "../shared/Quote";


const Resources = () => {


    const {data, isSuccess} = useFetchCatResources();
    // const {data, status, isSuccess} = useFetchCatResources();

    if (!isSuccess) 
        return ( <div>Cat resources not found.</div> );


    return (

        <main className="container main_content_box">
            <Header subtitle="Cats"/>

            <div id="section_housecats" className="row resource_section">
                <div className="col-md-12">
                    {data.map(x => <Resource {...x} key={x.resourceId} />)}
                </div>
            </div>
            <Quote/>
        </main>
    );
  };


  export default Resources;