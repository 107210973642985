import { useQuery } from '@tanstack/react-query'
import axios, { AxiosError } from "axios";

import Config from "../config";

import { Plant } from "./../types/plant";
import { FilterValues } from "../types/filtervalues";



const getPlants = async (filter: FilterValues): Promise<Plant[]> => 

  await axios.post(`${Config.baseApiUrl}/flora`
                                , JSON.stringify(filter)
                                , {headers: {'Content-Type': 'application/json; charset=utf-8'}})
                            .then((resp) => resp.data)


const useFetchPlant = (plantKeyId: string) => {

  return useQuery<Plant, AxiosError>({
        queryKey: ["plant", plantKeyId]
      , queryFn: () => getPlant(plantKeyId)
      , staleTime: 3600000
  });

};


const getPlant = async (keyId: string): Promise<Plant> => 
  
  await axios.get(`${Config.baseApiUrl}/flora/` + keyId)
                            .then((resp) => resp.data)




// const useAddAnimal = () => {
//   const queryClient = useQueryClient();
//   const nav = useNavigate();
//   return useMutation<AxiosResponse, AxiosError<Problem>, Animal>(
//     (h) => axios.post(`${Config.baseApiUrl}/fauna`, h),
//     {
//       onSuccess: () => {
//         queryClient.invalidateQueries("fauna");
//         nav("/");
//       },
//     }
//   );
// };

// const useUpdateHouse = () => {
//   const queryClient = useQueryClient();
//   const nav = useNavigate();
//   return useMutation<AxiosResponse, AxiosError<Problem>, Animal>(
//     (a) => axios.put(`${Config.baseApiUrl}/fauna`, a),
//     {
//       onSuccess: (_, animal) => {
//         queryClient.invalidateQueries("fauna");
//         nav(`/fauna/${animal.keyId}`);
//       },
//     }
//   );
// };

// const useDeleteHouse = () => {
//   const queryClient = useQueryClient();
//   const nav = useNavigate();
//   return useMutation<AxiosResponse, AxiosError, Animal>(
//     (a) => axios.delete(`${Config.baseApiUrl}/fauna/${a.keyId}`),
//     {
//       onSuccess: () => {
//         queryClient.invalidateQueries("fauna");
//         nav("/");
//       },
//     }
//   );
// };

export {
  getPlants,
  useFetchPlant,
  getPlant
};
