import axios from "axios";
// import axios, { AxiosError, AxiosResponse } from "axios";

import Config from "../config";

import { PhotoPage } from '../types/photopage';
import { PhotoDetails } from "../types/photodetails"
import { FilterValues } from "../types/filtervalues";


const getPhotos = async (filter: FilterValues): Promise<PhotoPage> => 
                      await axios.post(`${Config.baseApiUrl}/photos`
                                                    , JSON.stringify(filter)
                                                    , {headers: {'Content-Type': 'application/json; charset=utf-8'}})
                                                .then((resp) => resp.data)

const getPhotoDetails = async (photoId: number): Promise<PhotoDetails> => 
                      await axios.post(`${Config.baseApiUrl}/photodetails/` + photoId
                                        , {headers: {'Content-Type': 'application/json; charset=utf-8'}})
                                        .then((resp) => resp.data)


export {
    getPhotos
  , getPhotoDetails
};
