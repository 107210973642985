import axios, { AxiosError } from "axios";
import { useQuery } from "@tanstack/react-query";

import Config from "../config";

import { Resource } from "./../types/resource";


const showCategory = async (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();

      const button: HTMLButtonElement = event.currentTarget;
      const categoryId = "section_" + button.value;

      const categories = document.querySelectorAll(".resource_section");
 
      categories.forEach(function (x) {
          let catid = x.getAttribute("id");
          if (catid === categoryId)
              x.setAttribute("style", "display: block");
          else
              x.setAttribute("style", "display: none");
      });
}


const useFetchResources = () => {

    return useQuery<Resource[], AxiosError>({ 
                    queryKey: ["resources"],
                    staleTime: 3600000,
                    queryFn: async () => 
                                axios
                                  .get(`${Config.baseApiUrl}/resources`)
                                  .then((resp) => resp.data)
                });
  };


const useFetchCatResources = () => {

    return useQuery<Resource[], AxiosError>({ 
                    queryKey: ["cats"],
                    queryFn: async () => 
                                axios
                                  .get(`${Config.baseApiUrl}/cats`)
                                  .then((resp) => resp.data)
                });
  };


export {
  showCategory,
  useFetchResources,
  useFetchCatResources
};