import { useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import axios from "axios";
// import axios, { AxiosError, AxiosResponse } from "axios";

import Config from "../../config";

import { Error } from "../../types/error";

import { processErrors, addValidationIssue, showErrorSummary, clearFormErrors, validateCaptcha } from "../../hooks/ErrorHooks";

import Header from "../../main/frame/Header";
import Captcha from '../shared/Captcha';
import Quote from '../shared/Quote';


// React 'controlled' form

// 1. user enters data which calls handleChange() methods
// 2. user clicks submit
// 3. call contactValidate()
// 4. call contactSend()
// 5. call Mutation.mutate()
// 6. call contactReceive()


const ContactForm = () => {

    const [formStatus, setFormStatus] = useState('SEND')
    const [formData, setFormData] = useState({nameFirst: "", nameLast: "", email: "", photoId: "", message: "", frcCaptchaResponse: ""})

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { id, value } = event.target;
        setFormData((formData) => ({ ...formData, [id]: value }));
    };

    const handleMessageChange = (event: React.ChangeEvent<HTMLTextAreaElement>)  => {
        const textarea = document.getElementById('message') as HTMLTextAreaElement;
        let txtMessage = "";

        if (textarea) 
            txtMessage = textarea.value;

        // access textarea value
        setFormData((prevFormData) => ({ ...prevFormData, message: txtMessage}));
        // console.log("contact message: " + {txtMessage});
      };

    const contactValidate = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        clearFormErrors("form_contact");
        setFormStatus('Validating...');
        
        // validate main form
        let formValid = false;
        let form = document.getElementById("form_contact") as HTMLFormElement;

        if (form)
            formValid = form.checkValidity();
        if (!formValid) {
            const msg = document.getElementById('message') as HTMLTextAreaElement;
            if (!msg.checkValidity())
                addValidationIssue("", "Please enter a message.", "");
            const email = document.getElementById("email") as HTMLInputElement;
            if (!email.checkValidity())
                addValidationIssue("", "Please enter a valid email address, or leave that field empty.", "");
        }

        // validate captcha
        const captchaValid = validateCaptcha(formData);

        if (formValid && captchaValid)
            contactSend();
        else {
            // console.log("form invalid");
            form.className = "was-validated"; // display error(s)
            setFormStatus("SEND"); // reset submit button
            showErrorSummary();
        }
    }


    const contactSend = () => {
        setFormStatus('Submitting...');
        mutation.mutate();
      }


    const contactReceive = (data: Error[]) => {
        // console.log("response received!");
        if (data.length > 0) {
            processErrors(data);
            setFormStatus("SEND");
        }
        else {
            let form = document.getElementById("form_contact") as HTMLFormElement;
            let thanks = document.getElementById("form_confirm") as HTMLElement;
            form.style.display = "none";
            thanks.style.display = "block";
        }
    }


    const mutation = useMutation({
        mutationFn: () => {
          return axios.post(`${Config.baseApiUrl}/contactadd`, formData)
        },
        onError: (error, variables, context) => {
            // console.log(error.message); // todo: replace this
            // display generic error message in error summary box
        },
        onSuccess: (data, variables, context) => {
            let errors = data.data as Error[];
            contactReceive(errors);
        },
        onSettled: (data, error, variables, context) => {
            // console.log("onSettled() called..."); 
        }
    })


    return (
        <main className="main_content_box">
            <div className="container">
                <Header subtitle="Contact"/>
                <div className="row">
                    <div className="col-md-12 intro_copy">
                        <p>If you have comments, suggestions, questions, corrections - if you can help identify something - or anything else, It would be great to hear from you.</p>
                        <p>* Only the 'message' and captcha fields are required.</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 contact_form_box">
                        <form id="form_contact" method="post" onSubmit={e => contactValidate(e)} className="needs-validation" noValidate>
                            <div id="validationSummaryBox">
                                <div id="validIntro">Please review the below issues.</div>
                                <ul id="vSummary"></ul>
                            </div>
                            <label>First Name
                                <input id="nameFirst" maxLength={100} onChange={handleChange} className="form-control"/>
                            </label>
                            <label>Last Name
                                <input id="nameLast" maxLength={100} onChange={handleChange} className="form-control"/>
                            </label>
                            <label>Email
                                <input id="email" type="email" maxLength={100} onChange={handleChange} className="form-control" pattern="[a-z0-9._%+\-]+@[a-z0-9.\-]+\.[a-z]{2,}$" />
                                <div className="invalid-tooltip">Please enter a valid email address.</div>
                            </label>
                            <label>Photo ID (if applicable)
                                <input id="photoId" maxLength={50} onChange={handleChange} className="form-control"/>
                            </label>
                            <label className="form-label">Message *
                                <textarea id="message" maxLength={4000} rows = {10} cols = {60} onChange={handleMessageChange} className="form-control" required/>
                                <div className="invalid-tooltip">Please enter a message.</div>
                            </label>
                            <Captcha/>
                            <button id="btn_contact_submit" className="btn btn-secondary" type="submit" value="send" disabled={formStatus !== "SEND"}>{formStatus}</button>
                        </form>
                        <div id="form_confirm">
                            <p>Form submitted.  Thank you for writing!</p>
                        </div>
                    </div>
                    <div className="col-md-6 contact_form_photo">
                        <img id="img_contact_photo" src="../../assets/images/contact/contact_1.jpg" alt="monarchs on blazing stars" />
                    </div>
                </div>
            </div>
            <Quote/>
        </main>
    );
};

export default ContactForm;