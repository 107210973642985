import { useParams } from "react-router-dom";

import { CPLink } from "../../types/cplink";
import { Photo } from '../../types/photo';

import { useFetchPlant } from "../../hooks/PlantHooks";

import Gallery from '../shared/Gallery';
import Quote from "../shared/Quote";


const PlantDetail = () => {

    const { keyId } = useParams();

    if (!keyId)
        throw Error("Fauna not found.");

    const { data, status, isSuccess } = useFetchPlant(keyId);  // note ! = non-null assertion operator (not needed here because if the if (!keyId) check.)

    if (!isSuccess) 
      return ( <div>Flora data not found.</div> );


    const bannerPath = `../../assets/images/_banners/${data.banner.fileName}`;  // note: use 'backtick' character (not single or double quote) see backtick/tilde key


    let pics: Photo[] = [];  // declare array in typescript

    if (data.photos) {
        data.photos.forEach((p) => {

            const imgPathFull = `/assets/images/_full/${p.fileName}`; // note: use 'backtick' character (not single or double quote) see backtick/tilde key
            const imgThumbPath = `/assets/images/_thumb/${p.thumbFileName}`;

            pics.push({photoId: p.photoId, fileName: imgPathFull, thumbFileName: imgThumbPath, alt: p.alt, height: p.height, width: p.width, caption: p.caption, isKey: false})
        })
    }


    return (

        <div className="main_content_box">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 text-center">
                            <img src={bannerPath} className="img-responsive center-block" alt={data.banner.alt}/>
                    </div> 
                </div>
                <div className="row">
                    <div className="col-md-12 text-center detail_title">{data.nameDisplay}</div>
                </div>
                <div className="row">
                    <div className="col-md-12 text-center detail_name_sci">{data.nameScientific}</div>
                </div>
                
                <div className="row">
                    <div className="col-md-12 detail_section_title">Notes:</div>
                </div>
                <div className="row resource_box">
                    <div className="col-sm-12 detail_notes">
                        <div dangerouslySetInnerHTML={{ __html: data.notes}}></div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 detail_section_title">Links:</div>
                </div>
                <div className="row">
                    {data.links.map((l: CPLink) => (
                        <div className="col-sm-4 detail_link_box" key={l.linkId}>
                            <a href={l.href} target="_blank" rel="noopener noreferrer">
                                <button>{l.text}</button>
                            </a>
                        </div>
                    ))}
                </div>
                <div className="row">
                    <div className="col-md-12 detail_section_title">Photos:</div>
                </div>
                <div id="photo_gallery_box" className="row">
                    <Gallery photos={pics}/>
                </div>
            </div>
            <Quote/>
        </div>
    );
  };
  
  export default PlantDetail;