import { NavLink } from "react-router-dom";
import { mobileNavClick } from "../../hooks/SharedHooks";

const Navigation = () => {

  return (
    <div className="main_nav_box">
      <nav className="container">
        <h2 className="hidden">Main Navigation</h2>
        <ul className="main_nav_lnks">
          <li className="main_nav_home"><NavLink to="/" className={({isActive}) => isActive ? "active" : "main_nav_home"}>Home</NavLink></li>
          <li><NavLink to="/contact" className={({isActive}) => isActive ? "active" : ""}>Contact</NavLink></li>
          <li><NavLink to="/photos" className={({isActive}) => isActive ? "active" : ""}>Photos</NavLink></li>
          <li><NavLink to="/fauna" className={({isActive}) => isActive ? "active" : ""}>Fauna</NavLink></li>
          <li><NavLink to="/flora" className={({isActive}) => isActive ? "active" : ""}>Flora</NavLink></li>
          <li><NavLink to="/resources" className={({isActive}) => isActive ? "active" : ""}>Resources</NavLink></li>
          <li><NavLink to="/plans/blueprint2" className={({isActive}) => isActive ? "active" : ""}>Blueprint</NavLink></li>
        </ul>
        <div className="mobile_nav_btn" onClick={mobileNavClick}>
            <img src="../../assets/images/nav/mobile_nav_btn.png" alt="show/hide mobile nav" />
        </div>
        <ul id="mobNavBox" className="mobile_nav_lnks" data-target="navMobileLinks">
          <li onClick={mobileNavClick}><NavLink to="/" className={({isActive}) => isActive ? "active" : "main_nav_home"}>Home</NavLink></li>
          <li onClick={mobileNavClick}><NavLink to="/plans/blueprint2" className={({isActive}) => isActive ? "active" : ""}>Blueprint</NavLink></li>
          <li onClick={mobileNavClick}><NavLink to="/resources" className={({isActive}) => isActive ? "active" : ""}>Resources</NavLink></li>
          <li onClick={mobileNavClick}><NavLink to="/flora" className={({isActive}) => isActive ? "active" : ""}>Flora</NavLink></li>
          <li onClick={mobileNavClick}><NavLink to="/fauna" className={({isActive}) => isActive ? "active" : ""}>Fauna</NavLink></li>
          <li onClick={mobileNavClick}><NavLink to="/photos" className={({isActive}) => isActive ? "active" : ""}>Photos</NavLink></li>
          <li onClick={mobileNavClick}><NavLink to="/contact" className={({isActive}) => isActive ? "active" : ""}>Contact</NavLink></li>
        </ul>
      </nav>
    </div>
  );
};

export default Navigation;
