
import { CPLink } from "../../types/cplink"

import { boxLinkHover, boxLinkLeave } from "../../hooks/HomeHooks";


const HomeBoxLink = ({href, imgFileName, altText, hoverText }: CPLink) => {

    href = "/" + href;

    const lnkImage = "../../assets/images/home/links/" + imgFileName;

    return (
        <div className="col-md-4 box_link_box">
            <div className="box_link" onMouseOver={boxLinkHover} onMouseLeave={boxLinkLeave}>
                <a href={href} rel="canonical">
                    <img src={lnkImage} alt={altText}/>
                    <span className="box_link_overlay">
                        {hoverText}
                    </span>
                </a>
            </div>
        </div>
    );
}

export default HomeBoxLink;
