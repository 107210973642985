import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query'
import { AxiosError } from "axios";
import ReactPaginate from 'react-paginate';

import { Photo } from "../../types/photo";
import { PhotoPage } from '../../types/photopage';
import { FilterValues } from "../../types/filtervalues"

import { useFetchUser, authRoleCheck, useLogout } from "../../hooks/AdminHooks";
import { getPhotos } from "../../hooks/PhotoHooks";

import Header from "../../main/frame/Header";
import PhotoFilterForm from "../photos/PhotoFilterForm";
import PhotoLink from './PhotoLink';
import NoResults from '../shared/NoResults';


const defaultFilter = {
    category: ''
  , searchColumn: ''
  , searchTerm: ''
  , sortColumn: 'dtu'
  , sortDirection: 'desc'
  , pageId: 1
}

const localKeyFilter: string = "cpPhotoFilterIndex";


const getFilter = () => {

    let localFilter = window.sessionStorage.getItem(localKeyFilter);
  
    if (localFilter) {
      return JSON.parse(localFilter); 
    }
  
    return defaultFilter;
  }


const PhotoIndex = () => {
  
    const { data: userClaims } = useFetchUser(); // rename 'data' to userClaims to avoid naming conflicts when you have more than one 'useQuery'
    const admin = userClaims ? authRoleCheck(userClaims) : null;


    let [filter, setFilter] = useState<FilterValues>(getFilter());

    const { data } = useQuery<PhotoPage, AxiosError>({
                                                      queryKey: ["photolistindex", filter.searchColumn, filter.searchTerm, filter.sortColumn, filter.sortDirection, filter.category, filter.pageId]
                                                    , queryFn: () => getPhotos(filter)
                                                    , staleTime: 3600000
                                                    });

    let pageCount = (data && data.totalPageCount) ? data.totalPageCount : 0;


    /* get/set filter in local storage */
    useEffect(() => {
        let localFilter = window.sessionStorage.getItem(localKeyFilter);
        if (localFilter) {
          setFilter(JSON.parse(localFilter));
        }
      }, []);
    
    useEffect(() => {
        const strFilter = JSON.stringify(filter);
        window.sessionStorage.setItem(localKeyFilter, strFilter);
      }, [filter]);


    /* filter onchange methods */
    const onChangeFilterText = (inputName: string) => {
        return (event: React.ChangeEvent<HTMLInputElement>) => {
          setFilter({ ...filter, pageId: 1, [inputName]: event.target.value });
        };
    };

    const onChangeFilterDDL = (inputName: string) => {
        return (event: React.ChangeEvent<HTMLSelectElement>) => {
          setFilter({ ...filter, pageId: 1, [inputName]: event.target.value });
        };
    };

    const onChangeFilterRadio = (inputName: string) => {
        return (event: React.ChangeEvent<HTMLInputElement>) => {

          let direction = 'asc';

          // const btnsRadio = document.getElementsByName("SortDirection") as NodeListOf<HTMLInputElement>; // in this case, no need to get all radio buttons
          const radioDesc = document.getElementById("sort_dir_desc") as HTMLInputElement;
          if (radioDesc.checked === true)
            direction = 'desc';

            setFilter({ ...filter, pageId: 1, [inputName]: direction });
        };
    };

    let pics: Photo[] = [];  // declare array in typescript

    if (data) {
        data.items.forEach((p) => {

            const imgPathFull = `/assets/images/_full/${p.fileName}`; // note: use 'backtick' character (not single or double quote) see backtick/tilde key
            const imgThumbPath = `/assets/images/_thumb/${p.thumbFileName}`;

            pics.push({photoId: p.photoId, fileName: imgPathFull, thumbFileName: imgThumbPath, alt: p.alt, height: p.height, width: p.width, caption: p.caption, isKey: false})
        })
    }

    const handlePageClick = (selectedItem: {selected: number}): void => {
        // console.log(selectedItem.selected);

        const pgId = selectedItem.selected + 1;
        setFilter(filter => ({...filter, pageId: pgId}));
    }


    return (

        <main className="container">
             {userClaims && admin  
             && (
                <div>
                    <Header subtitle="Photo Index"/>
                    <div className="row">
                        <div className="col-sm-3 detail_link_box">
                            <a href="/cp" rel="noopener noreferrer">
                                <button id="btn_photo_index" >Admin Home</button>
                            </a>
                        </div>
                        <div className="col-sm-3 detail_link_box">
                            <a href="/cp/keywordadd" target="_blank" rel="noopener noreferrer">
                                <button>Add Keyword</button>
                            </a>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 search_tool_col">
                        <PhotoFilterForm searchColumn={filter.searchColumn} searchTerm={filter.searchTerm} sortColumn={filter.sortColumn} sortDirection={filter.sortDirection} onChangeFilterText={onChangeFilterText} onChangeFilterDDL={onChangeFilterDDL} onChangeFilterRadio={onChangeFilterRadio} />
                        </div>
                    </div>
                    <div className="row photo-pgbtns-box">
                        <div className="col-sm-12">
                            <ReactPaginate 
                                nextLabel="next >"
                                onPageChange={handlePageClick}
                                pageRangeDisplayed={3}
                                marginPagesDisplayed={2}
                                pageCount={pageCount}
                                previousLabel="< previous"
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
                                previousClassName="page-item"
                                previousLinkClassName="page-link"
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
                                breakLabel="..."
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
                                containerClassName="pagination"
                                activeClassName="active"
                                renderOnZeroPageCount={NoResults}
                                forcePage={filter.pageId - 1}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div id="photoLinksBox"  className="row">
                                {pics.map(x => <PhotoLink {...x} key={x.photoId} />)} 
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </main>
    );
  };
  
  export default PhotoIndex;
