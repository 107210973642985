import { DebounceInput } from 'react-debounce-input';

import { FilterHelpers } from "../../types/filterhelpers"

import Form from 'react-bootstrap/Form';


const PhotoFilterForm = ({searchColumn="kwd", searchTerm="", sortColumn="dtu", sortDirection="desc", onChangeFilterText, onChangeFilterDDL, onChangeFilterRadio}: FilterHelpers) => {

    let defaultSortDir = "desc"

    if (sortDirection === "asc") {
        defaultSortDir = "asc";
    }


    return ( 
        <form id="plantListFilter" >
            <div className="row">
                <div className="col-lg-6">
                    <fieldset id="search_fs">
                        <legend>&nbsp;&nbsp;SEARCH&nbsp;&nbsp;</legend>
                        <Form.Select id="search_ddl_col" defaultValue={searchColumn} aria-label="Choose column" onChange={onChangeFilterDDL("searchColumn")} >
                            <option value="kwd">Keyword</option>
                            <option value="pid">Photo Id</option>
                            <option value="cap">Caption</option>
                            <option value="dtu">Date Uploaded</option>
                            <option value="dtt">Date Taken</option>
                        </Form.Select>
                        <div id="search_comp_box">
                            <span id="search_comp">
                                <span id="search_contains">contains</span>
                            </span>
                            <div id="search_term_box">
                                <DebounceInput id="search_tbx_term" value={searchTerm} autoComplete="off" onChange={onChangeFilterText("searchTerm")} minLength={1}  debounceTimeout={750} />
                            </div>
                        </div>
                    </fieldset>
                </div>
                <div className="col-lg-6 sort_col">
                    <fieldset id="sort_fs">
                        <legend>&nbsp;&nbsp;SORT&nbsp;&nbsp;</legend>
                        <div>
                            <Form.Select id="sort_ddl_col" defaultValue={sortColumn} aria-label="Choose column" onChange={onChangeFilterDDL("sortColumn")} >
                                <option value="dtu">Date Uploaded</option>
                                <option value="dtt">Date Taken</option>
                                <option value="pid">Photo Id</option>
                            </Form.Select>
                            <div id="sort_radio">
                                <label>
                                    <input checked={defaultSortDir === "asc"} className="search_sort" id="sort_dir_asc" name="SortDirection" type="radio" value="asc" onChange={onChangeFilterRadio("sortDirection")} />
                                    asc
                                </label>
                                <label>
                                    <input className="search_sort" id="sort_dir_desc" checked={defaultSortDir === "desc"} name="SortDirection" type="radio" value="desc" onChange={onChangeFilterRadio("sortDirection")}/>
                                    desc
                                </label>
                            </div>
                        </div>
                    </fieldset>
                </div>
            </div>
        </form>
    );
  };
  
  export default PhotoFilterForm;