import { Resource } from "../../types/resource";


const Resources = ({resourceId, categoryId, title, subtitle, imgFileName, imgAlt, description, sortOrder, href, deepLink }: Resource) => {

    const hasImage = imgFileName ? true : false;
    const hasHref = href ? true : false;

    let imgRoot = "../../assets/images/resources/";
    let imgFolder = "";

    switch(categoryId)
    {
        case 8: imgFolder += "/books/";
                break;
        case 9: imgFolder += "/websites/";
                break;
        case 10: imgFolder += "/nurseries/";
                break;
        case 11: imgFolder += "/nonprofits/";
                break;
        case 12: imgFolder += "/finance/";
                break;
        case 13: imgFolder += "/visitors/";
                break;
        case 14: imgFolder += "/housecats/";
                break;
        case 15: imgFolder += "/housecats/references/thumbs/";
                break;
    }

    const imgPathFull = imgRoot + imgFolder + imgFileName;
    const tgt = (href.startsWith("/")) ? "_self" : "_blank";


    return (
        <div id={deepLink} className="row resource_box">
            <div className="col-md-4 resource_thumb">
                {(hasHref && hasImage)      /* has both image and link */
                    ? (<a href={href} target={tgt} rel="noreferrer">
                            <img src={imgPathFull} alt={imgAlt} />
                       </a>)
                    : (hasImage)            /* has image but no link */
                        ? (<img src={imgPathFull} alt={imgAlt} />)
                        : (<div className="resource_subtitle">{subtitle}</div>)
                }
            </div>
            <div className="col-md-8 resource_desc_box">
                <div className="resource_title">
                    {title}
                </div>
                <div className="resource_subtitle">
                    {hasHref
                        ? (<a href={href} target="_blank" rel="noreferrer">{subtitle}</a>)
                        : (<div className="resource_subtitle">{subtitle}</div>)
                    }
                </div>
                <div className="resource_desc">
                    <div dangerouslySetInnerHTML={{ __html: description}}></div>
                </div>
            </div>
        </div>

    );
  };
  
  export default Resources;

