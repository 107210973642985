
const boxLinkHover = (event: React.MouseEvent<HTMLDivElement>) => {
  const box: HTMLDivElement = event.currentTarget;

  let overlay = box.querySelector(".box_link_overlay") as HTMLDivElement | null;

  if (overlay)
    overlay.style.display = "block";
};


const boxLinkLeave = (event: React.MouseEvent<HTMLDivElement>) => {
  const box: HTMLDivElement = event.currentTarget;

  let overlay = box.querySelector(".box_link_overlay") as HTMLDivElement | null;

  if (overlay)
    overlay.style.display = "none";
};



export {
  boxLinkHover,
  boxLinkLeave
};