import { useState, useEffect } from 'react'
import axios from "axios";
// import axios, { AxiosError, AxiosResponse } from "axios";

import Config from "../../config";

import { Error } from "../../types/error";
import { PhotoDup } from "../../types/photodup";

import { processErrors } from "../../hooks/ErrorHooks";
import { useFetchUser, authRoleCheck, useLogout } from "../../hooks/AdminHooks";

import Header from "../../main/frame/Header";


const PhotosImport = () => {

    const { data: userClaims } = useFetchUser(); // rename 'data' to userClaims to avoid naming conflicts when you have more than one 'useQuery'
    const admin = userClaims ? authRoleCheck(userClaims) : null;

    const [formStatus, setFormStatus] = useState('Import Photo Data')



    const photosImportSend = () => {
        setFormStatus('Submitting...');

        axios.post(`${Config.baseApiUrl}/photosimport`)
                                .then((response) => { photosImportReceive(response.data);});
      }


    const photosImportReceive = (data: Error[]) => {
        console.log("response received!");
        if (data.length > 0) {
            processErrors(data);
            setFormStatus("Import Photo Data");
            checkErrorsForDups(data);
        }
        else {
            // success : no errors or dups
            setFormStatus('Photo Data Imported');
            validationBoxSetColor("success");
        }
    }

    const checkErrorsForDups = (errors: Error[]) => {
        if (errors && errors.length > 0) {
            for (let i = 0; i < errors.length; i++) {
                if (errors[i].errorMessage === "Duplicate photo file names uploaded.") {
                    if (errors.length === 1) {
                        setFormStatus('Photo Data Imported');
                        validationBoxSetColor("warning"); // only show warning box when dups and no other errors
                    }
                        
                    photosDupsSend();
                    break;
                }
            }
        }
    };
      

    const validationBoxSetColor = (type: string) => {

        let summaryBox = document.getElementById("validationSummaryBox");
        let summaryIntro = document.getElementById("validIntro");

        if (summaryBox && summaryIntro) {
        switch(type)
        {
            case "warning": 
                summaryBox.classList.add("warningBox");
                break;
            case "success":
                summaryIntro.innerText = "All good.";
                summaryBox.classList.add("successBox");
                break;
            }
        }
    }


    // get recent photo filename dups
    const photosDupsSend = () => {
        axios.post(`${Config.baseApiUrl}/photodupsget`)
                                .then((response) => { photosDupsReceive(response.data);});
    }

    // receive and display recent photo filename dups
    const photosDupsReceive = (data: PhotoDup[]) => {
        console.log("photo dups response received!");
        if (data && data.length > 0) {
            data.forEach(processDup);
          }
    }

    const processDup = (value: PhotoDup, index: number, array: PhotoDup[]) => {
        displayPhotoDup(value.photoDuplicateId, value.dateUploaded, value.fileName_Old, value.fileName_New);
    }

    // add photo dup to table
    const displayPhotoDup = (photoDuplicateId: number, dateUploaded: Date, fileName_Old: string, fileName_New: string) => {
        const row = document.createElement("tr");

        const col1 = document.createElement("td");
        col1.innerText = photoDuplicateId.toString();

        const col2 = document.createElement("td");
        col2.innerText = dateUploaded.toString();

        const col3 = document.createElement("td");
        col3.innerText = fileName_Old;

        const col4 = document.createElement("td");
        col4.innerText = fileName_New;

        row.append(col1);
        row.append(col2);
        row.append(col3);
        row.append(col4);

        const tbl = document.getElementById("tblPhotoDupsBody");
        if (tbl)
            tbl.append(row);
    };

    
    return (
        <div className="container main_content_box">
            {userClaims && admin
                && (
                    <div>
                        <Header subtitle="Photo Data Import"/>
                        <div className="row">
                            <div className="col-sm-3 detail_link_box">
                                <a href="/cp" rel="noopener noreferrer">
                                    <button id="btn_photo_index" >Admin Home</button>
                                </a>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3 text-center">
                                <ul className="adminCatLinks">
                                    <li>
                                        <button id="btnPhotoDataImport" className="btn btn-secondary" type="button" onClick={photosImportSend} disabled={formStatus !== "Import Photo Data"}>{formStatus}</button>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-md-9">
                                <div id="validationSummaryBox">
                                    <div id="validIntro">Please review the below issues.</div>
                                    <ul id="vSummary"></ul>
                                </div>
                            </div>
                        </div>
                        <div id="piListDuplicates" className="row">
                            <p className="tblDupsTitle">List of duplicates uploaded</p>
                            <table id="tblPhotoUploadDups">
                                <thead>
                                    <tr>
                                        <td>ID</td>
                                        <td>Date Uploaded</td>
                                        <td>Original</td>
                                        <td>New</td>
                                    </tr>
                                </thead>
                                <tbody id="tblPhotoDupsBody">
                                </tbody>
                            </table>
                        </div>
                    </div>
                )} 
        </div>
    );
};

export default PhotosImport;