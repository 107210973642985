
const mobileNavClick = (event: React.MouseEvent<HTMLElement>) => {
  event.preventDefault();
  const mobileNavBox = document.getElementById("mobNavBox");
  if (mobileNavBox) {
    const boxVisibility = window.getComputedStyle(mobileNavBox, null).visibility;
    if (boxVisibility === "hidden") {
      mobileNavBox.classList.add("slide-down");
      mobileNavBox.classList.remove("slide-up");
    }
    else {
      mobileNavBox.classList.add("slide-up");
      mobileNavBox.classList.remove("slide-down");
    }
  }
};


export {
    mobileNavClick
};
