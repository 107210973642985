const Config = {
    // baseApiUrl: "https://localhost:4000/api", 
    // baseMvcUrl: "https://localhost:4000/"
    baseApiUrl: "/api",
    baseMvcUrl: ""
  };
  
//   const currencyFormatter = Intl.NumberFormat("en-US", {
//     style: "currency",
//     currency: "USD",
//     maximumFractionDigits: 0,
//   });
  


  export default Config;
  export { 
    //currencyFormatter 
};
  