import { Animal } from "../../types/animal";

const AnimalListRow = ({animalId, nameCommon, nameScientific, nameDisplay, keyId, aka, classTax, order, family, notes, thumbFileName, banner}: Animal) => {

    const href = `/fauna/${keyId}`;
    const hasThumb = (thumbFileName !== "cp_default.png");
    const thumbPath = `../../assets/images/_thumb/keys/${thumbFileName}`;  // note: use 'backtick' character (not single or double quote) see backtick/tilde key

    return (
        <tr>
            <td className="cell_center">
            {hasThumb &&
                    <a href={href}>
                        <img className="list_thumb" src={thumbPath} alt={nameDisplay}/>
                    </a>
                }
            </td>
            <td><a href={href}>{nameCommon}</a></td>
            <td><a href={href}>{nameScientific}</a></td>
            <td className="cell_center">{classTax}</td>
            <td className="cell_center">{order}</td>
            <td>{notes}</td>
        </tr>
    );
  };
  
  export default AnimalListRow;