import { useEffect, useRef } from "react"
// import React, { useEffect, useRef } from "react"
import { WidgetInstance } from "friendly-challenge"


const Captcha = () => {
  const container = useRef()
  const widget = useRef()

  const doneCallback = (solution: string) => {
    // console.log("Captcha was solved. The form can be submitted.")
    // console.log(solution)
  }

  const errorCallback = (err: string) => {
    // console.log("There was an error when trying to solve the Captcha.")
    // console.log(err)
  }

  useEffect(() => {
    if (!widget.current && container.current) {

// eslint-disable-next-line @typescript-eslint/ban-ts-comment     
// @ts-ignore
      widget.current = new WidgetInstance(container.current, {
        startMode: "none", // disable auto start
        doneCallback: doneCallback,
        errorCallback: errorCallback,
        language: "en", // Default is "en". Change this if you prefer other language.
      })
    }

    return () => {
// eslint-disable-next-line @typescript-eslint/ban-ts-comment     
// @ts-ignore       
      if (widget.current !== undefined) widget.current.reset()
    }
  }, [container])

  return (
    <div
// eslint-disable-next-line @typescript-eslint/ban-ts-comment     
// @ts-ignore
      ref={container}
      className="frc-captcha"
      data-sitekey={process.env.REACT_APP_FRC_SITE_KEY}
    />
  )
}

export default Captcha