import Header from "../../main/frame/Header";
import Quote from "../shared/Quote";


const Plan2 = () => {

    const imgEast = "../../assets/images/blueprint/east2.jpg";
    const imgWest = "../../assets/images/blueprint/west2.jpg";

    return (

        <main className="container main_content_box">
            <Header subtitle="Blueprint 2.0"/>
            <div className="row">
                <div className="col-md-12 intro_copy">
                    <p>Here is the most current version of the plan.  I've needed to change some of the plant selections, mostly to reduce vulnerability to japanese beetles.  But the structure of the plan is mostly the same.</p>
                    <p>I have a long way to go.  But I try to make a little progress every year.</p>
                    <p><a href="/plans/blueprint1" rel="canonical"><b>Here is an earlier version</b></a> of the plan that doesn't cover the whole yard.</p>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12 blueprint_main">
                    <div className="blueprint_top">
                        <img id="blueprint_top" src={imgEast} alt="current blueprint for east side of yard" className="img-responsive"/>
                    </div>
                    <div className="blueprint_bottom">
                        <img id="blueprint_bottom" src={imgWest} alt="current blueprint for west side of yard" className="img-responsive"/>
                    </div>
                </div>
            </div>
            <Quote/>
        </main>
    );
  };
  
  export default Plan2;


