import { NavLink } from "react-router-dom";

const Footer = () => {

  return (
    <footer className="footer_box">
      <nav className="container">
        <ul className="footer_lnks">
          <li><NavLink to="/">Home</NavLink></li>
          <li><NavLink to="/plans/blueprint2">Blueprint</NavLink></li>
          <li><NavLink to="/resources">Resources</NavLink></li>
          <li><NavLink to="/flora">Flora</NavLink></li>
          <li><NavLink to="/fauna">Fauna</NavLink></li>
          <li><NavLink to="/photos">Photos</NavLink></li>
          <li><NavLink to="/contact">Contact</NavLink></li>
        </ul>
      </nav>
    </footer>
  );
};

export default Footer;
