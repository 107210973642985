const PhotoEditCheckbox = ({prefix = "", keyNumber = "", label = ""}) => {

    const keyid = prefix + "_" + keyNumber;

    return (
        <div className="checkbox photo_edit_cbx">
            <label>
                <input id={keyid} name={prefix} type="checkbox" value={keyNumber} />
                {label}
            </label>
        </div>
    );
  };
  
  export default PhotoEditCheckbox;