import { useEffect } from "react";
import { useQuery } from '@tanstack/react-query'
import axios, { AxiosError } from "axios";

import Config from "../config";

import { Claim } from "../types/claim";



import { PhotoEditOptions } from "../types/photoeditoptions";


const getPhotoEditCheckBoxOptions = async (): Promise<PhotoEditOptions[]> => 

        await axios.post(`${Config.baseApiUrl}/photoopts`)
                                        //, {headers: {'Content-Type': 'application/json; charset=utf-8'}})
                                    .then((resp) => resp.data)
                                    



/*****************/                          
/* authorization */
/*****************/   

const useFetchUser = () => {
    return useQuery<Claim[], AxiosError>({
        queryKey: ["user"],
        queryFn: () =>
        axios
            .get(`${Config.baseMvcUrl}/cp/getuser`, {withCredentials: true})   // ?slide=false -- to opt out of sliding cookie expiration
            .then((resp) => resp.data)
    });
};


const authRoleCheck = (claims: Claim[]) => {
    let auth = false;

    if (claims != null && claims.length > 0) {
        const c = claims.find((c) => c.type === "role" && c.value === "admin")
        if (c != null)
            auth = true;
    }
    return auth;
}


const useLogout = () => {
    return useEffect(() => {
        axios.put(`${Config.baseMvcUrl}/cp/logout`, {withCredentials: true})   // ?slide=false -- to opt out of sliding cookie expiration
    });
};




export {
      getPhotoEditCheckBoxOptions
    , useFetchUser
    , authRoleCheck
    , useLogout
};

  