import Header from "../../main/frame/Header";
import { useFetchUser, authRoleCheck, useLogout } from "../../hooks/AdminHooks";
import Quote from "../shared/Quote";


const AdminHome = () => {

    const { data: userClaims } = useFetchUser(); // rename 'data' to userClaims to avoid naming conflicts when you have more than one 'useQuery'
    const admin = userClaims ? authRoleCheck(userClaims) : null;

    
    return (
        <div className="container main_content_box">
            {userClaims && admin 
                && (
                    <div>
                        <Header subtitle="Admin"/>
                        <div className="row">
                            <div className="col-md-3 text-center">
                                <div className="adminCategory">Photos</div>
                                <ul className="adminCatLinks">
                                    <li><a href="/cp/photoindex">Index</a></li>
                                    <li><a href="#">Add Category</a></li>
                                    <li><a href="/cp/keywordadd">Add Keyword</a></li>
                                    <li><a href="/cp/photosimport">Import Photo Data</a></li>
                                </ul>
                            </div>
                            <div className="col-md-3 text-center">
                                <div className="adminCategory">
                                    <a href="#">Animals</a>
                                </div>
                            </div>
                            <div className="col-md-3 text-center">
                                <div className="adminCategory">
                                    <a href="#">Plants</a>
                                </div>
                            </div>
                            <div className="col-md-3 text-center">
                                <div className="adminCategory">
                                    <a href="#">Resources</a>
                                </div>
                            </div> 
                        </div>
                        <div id="logoutbox">
                            <a id="aLogout" href="/cp/logout">Log Out</a>
                        </div>
                    </div>
                )} 
            <Quote/>
        </div>
    );
};

export default AdminHome;