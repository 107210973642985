import { Plant } from "../../types/plant";

const PlantListRow = ({plantId, nameCommon, nameScientific, nameDisplay, keyId, aka, type, sun, moisture, season, height, heightSort, width, widthSort, notes, thumbFileName, banner}: Plant) => {

    const href = `/flora/${keyId}`;
    const hasThumb = (thumbFileName !== "cp_default.png");
    const thumbPath = `../../assets/images/_thumb/keys/${thumbFileName}`;  // note: use 'backtick' character (not single or double quote) see backtick/tilde key

    return (
        <tr>
            <td className="cell_center">
                {hasThumb &&
                    <a href={href}>
                        <img className="list_thumb" src={thumbPath} alt={nameDisplay}/>
                    </a>
                }
            </td>
            <td><a href={href}>{nameCommon}</a></td>
            <td><a href={href}>{nameScientific}</a></td>
            <td className="cell_center">{type}</td>
            <td className="cell_center">{sun}</td>
            <td className="cell_center">{moisture}</td>
            <td className="cell_center">{height}</td>
            <td className="cell_center">{width}</td>
            <td className="cell_center">{season}</td>
            <td>{notes}</td>
        </tr>
    );
  };
  
  export default PlantListRow;