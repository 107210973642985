import Form from 'react-bootstrap/Form';
import { DebounceInput } from 'react-debounce-input';

import { FilterHelpers } from "../../types/filterhelpers"


const PlantFilterForm = ({searchColumn="com", searchTerm="", sortColumn="com", sortDirection="asc", onChangeFilterText, onChangeFilterDDL, onChangeFilterRadio}: FilterHelpers) => {

    let defaultSortDir = "asc"

    if (sortDirection === "desc") {
        defaultSortDir = "desc";
    }


    return ( 
        <form id="plantListFilter" >
            <div className="row">
                <div className="col-lg-6">
                    <fieldset id="search_fs">
                        <legend>&nbsp;&nbsp;SEARCH&nbsp;&nbsp;</legend>
                        <Form.Select id="search_ddl_col" defaultValue={searchColumn} aria-label="Choose column" onChange={onChangeFilterDDL("searchColumn")} >
                            <option value="com">Common Name</option>
                            <option value="sci">Scientific Name</option>
                            <option value="typ">Type</option>
                            <option value="sun">Sun</option>
                            <option value="mst">Moisture</option>
                            <option value="hgt">Height</option>
                            <option value="wth">Width</option>
                            <option value="sea">Season</option>
                            <option value="not">Notes</option>
                        </Form.Select>
                        <div id="search_comp_box">
                            {/* todo: maybe add other comparer options */}
                            <span id="search_comp">
                                <span id="search_contains">contains</span>
                            </span>
                            <div id="search_term_box">
                                {/* <input id="search_tbx_term" autoComplete="off" type="text" onChange={onChangeFilterText("searchTerm")}  /> */}
                                <DebounceInput id="search_tbx_term" value={searchTerm} autoComplete="off" onChange={onChangeFilterText("searchTerm")} minLength={1}  debounceTimeout={750} />
                            </div>
                        </div>
                    </fieldset>
                </div>
                <div className="col-lg-6 sort_col">
                    <fieldset id="sort_fs">
                            <legend>&nbsp;&nbsp;SORT&nbsp;&nbsp;</legend>
                            <div>
                                <Form.Select id="sort_ddl_col" defaultValue={sortColumn} aria-label="Choose column" onChange={onChangeFilterDDL("sortColumn")} >
                                    <option value="com">Common Name</option>
                                    <option value="sci">Scientific Name</option>
                                    <option value="typ">Type</option>
                                    <option value="sun">Sun</option>
                                    <option value="mst">Moisture</option>
                                    <option value="hgt">Height</option>
                                    <option value="wth">Width</option>
                                    <option value="sea">Season</option>
                                </Form.Select>
                                <div id="sort_radio">
                                    <label>
                                        <input checked={defaultSortDir === "asc"} className="search_sort" id="sort_dir_asc" name="SortDirection" type="radio" value="asc" onChange={onChangeFilterRadio("sortDirection")} />
                                        asc
                                    </label>
                                    <label>
                                        <input className="search_sort" id="sort_dir_desc" checked={defaultSortDir === "desc"} name="SortDirection" type="radio" value="desc" onChange={onChangeFilterRadio("sortDirection")}/>
                                        desc
                                    </label>
                                </div>
                            </div>
                        </fieldset>
                </div>
            </div>
        </form>
    );
  };
  
  export default PlantFilterForm;