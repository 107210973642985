import Form from 'react-bootstrap/Form';
import { DebounceInput } from 'react-debounce-input';

import { FilterHelpers } from "../../types/filterhelpers"


const AnimalFilterForm = ({searchColumn="com", searchTerm="", sortColumn="com", sortDirection="asc", onChangeFilterText, onChangeFilterDDL, onChangeFilterRadio}: FilterHelpers) => {

    let defaultSortDir = "asc"

    if (sortDirection === "desc") {
        defaultSortDir = "desc";
    }


    return ( 
        <form id="animalListFilter" >
            <div className="row">
                <div className="col-lg-6">
                    <fieldset id="search_fs">
                        <legend>&nbsp;&nbsp;SEARCH&nbsp;&nbsp;</legend>
                        <Form.Select id="search_ddl_col" defaultValue={searchColumn} aria-label="Choose column" onChange={onChangeFilterDDL("searchColumn")} >
                            <option value="com">Common Name</option>
                            <option value="sci">Scientific Name</option>
                            <option value="cls">Class</option>
                            <option value="odr">Order</option>
                            <option value="not">Notes</option>
                        </Form.Select>
                        <div id="search_comp_box">
                            <span id="search_comp">
                                <span id="search_contains">contains</span>
                            </span>
                            <div id="search_term_box">
                                <DebounceInput id="search_tbx_term" value={searchTerm} autoComplete="off" onChange={onChangeFilterText("searchTerm")} minLength={1}  debounceTimeout={750} />
                            </div>
                        </div>
                    </fieldset>
                </div>
                <div className="col-lg-6 sort_col">
                    <fieldset id="sort_fs">
                        <legend>&nbsp;&nbsp;SORT&nbsp;&nbsp;</legend>
                        <div>
                            <Form.Select id="sort_ddl_col" defaultValue={sortColumn} aria-label="Choose column" onChange={onChangeFilterDDL("sortColumn")} >
                                <option value="com">Common Name</option>
                                <option value="sci">Scientific Name</option>
                                <option value="cls">Class</option>
                                <option value="odr">Order</option>
                            </Form.Select>
                            <div id="sort_radio">
                                <label>
                                    <input id="sort_dir_asc" checked={defaultSortDir === "asc"} className="search_sort" name="SortDirection" type="radio" value="asc" onChange={onChangeFilterRadio("sortDirection")} />
                                    asc
                                </label>
                                <label>
                                    <input id="sort_dir_desc" checked={defaultSortDir === "desc"} className="search_sort" name="SortDirection" type="radio" value="desc" onChange={onChangeFilterRadio("sortDirection")}/>
                                    desc
                                </label>
                            </div>
                        </div>
                    </fieldset>
                </div>
            </div>
        </form>
    );
  };
  
  export default AnimalFilterForm;