import { useFetchResources, showCategory } from "../../hooks/ResourceHooks";

import Header from "../../main/frame/Header";
import Resource from "../resources/Resource";
import Quote from "../shared/Quote";


const Resources = () => {

    const {data, isSuccess} = useFetchResources();
    // const {data, status, isSuccess} = useFetchResources();

    if (!isSuccess) 
        return ( <div>Resources not found.</div> );

    const books = data.filter(x => x.categoryId === 8);
    const websites = data.filter(x => x.categoryId === 9);
    const nurseries = data.filter(x => x.categoryId === 10);
    const nonprofits = data.filter(x => x.categoryId === 11);
    const finances = data.filter(x => x.categoryId === 12);
    const visitors = data.filter(x => x.categoryId === 13);
    const challenges = data.filter(x => x.categoryId === 14);


    return (

        <main className="container main_content_box">
            <Header subtitle="Resources"/>
            <div className="row">
                <div className="col-md-12 resource_links">
                    <button value="books" onClick={showCategory}>Books</button>
                    <button value="websites" onClick={showCategory}>Websites</button>
                    <button value="nurseries" onClick={showCategory}>Nurseries</button>
                    <button value="nonprofits" onClick={showCategory}>Nonprofits</button>
                    <button value="finances" onClick={showCategory}>Financial Assistance</button>
                    <button value="visitors" onClick={showCategory}>Visitor Suggestions</button>
                    <button value="challenges" onClick={showCategory}>Challenges</button>
                </div>
            </div>

            <div id="section_books" className="row resource_section">
                <div className="col-md-12">
                    <div className="row">
                        <div id="books" className="col-md-12 main_content_subtitle">
                            Books
                        </div>
                    </div>
                    {books.map(x => <Resource {...x} key={x.resourceId}/>)}  {/* {...x} = spread syntax to apply all properties */}
                </div>
            </div>

            <div id="section_websites" className="row resource_section">
                <div className="col-md-12">
                    <div className="row">
                        <div id="websites" className="col-md-12 main_content_subtitle">
                            Websites
                        </div>
                    </div>
                    {websites.map(x => <Resource {...x} key={x.resourceId} />)}
                </div>
            </div>

            <div id="section_nurseries" className="row resource_section">
                <div className="col-md-12">
                    <div className="row">
                        <div id="nurseries" className="col-md-12 main_content_subtitle">
                            Nurseries
                        </div>
                    </div>
                    {nurseries.map(x => <Resource {...x} key={x.resourceId} />)}
                </div>
            </div>

            <div id="section_nonprofits" className="row resource_section">
                <div className="col-md-12">
                    <div className="row">
                        <div id="nonprofits" className="col-md-12 main_content_subtitle">
                            Nonprofits
                        </div>
                    </div>
                    {nonprofits.map(x => <Resource {...x} key={x.resourceId} />)}
                </div>
            </div>

            <div id="section_finances" className="row resource_section">
                <div className="col-md-12">
                    <div className="row">
                        <div id="finances" className="col-md-12 main_content_subtitle">
                            Finances
                        </div>
                    </div>
                    {finances.map(x => <Resource {...x} key={x.resourceId} />)}
                </div>
            </div>

            <div id="section_visitors" className="row resource_section">
                <div className="col-md-12">
                    <div className="row">
                        <div id="visitors" className="col-md-12 main_content_subtitle">
                            Visitors
                        </div>
                    </div>
                    {visitors.map(x => <Resource {...x} key={x.resourceId} />)}
                </div>
            </div>

            <div id="section_challenges" className="row resource_section">
                <div className="col-md-12">
                    <div className="row">
                        <div id="challenges" className="col-md-12 main_content_subtitle">
                            Challenges
                        </div>
                    </div>
                    {challenges.map(x => <Resource {...x} key={x.resourceId} />)}
                </div>
            </div>
            <Quote/>
        </main>
    );
  };


  export default Resources;