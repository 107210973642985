import Header from "../../main/frame/Header";
import Quote from "../shared/Quote";


const Plan1 = () => {

    const imgEast = "../../assets/images/blueprint/east.jpg";
    const imgWest = "../../assets/images/blueprint/west.jpg";

    return (

        <main className="container main_content_box">
            <Header subtitle="Blueprint 1.0"/>
            <div className="row">
                <div className="col-md-12">
                    <p>Here is the first version of the plan.</p>
                    <p>The biggest difference is that it leaves a large area of lawn in the middle of the yard.</p>
                    <p><a href="/plans/blueprint2"><b>Click here to see the current version.</b></a></p>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12 blueprint_main">
                    <div className="blueprint_top">
                        <img id="blueprint_top" src={imgEast} alt="current blueprint for east side of yard" className="img-responsive" />
                    </div>
                    <div className="blueprint_bottom">
                        <img id="blueprint_bottom" src={imgWest} alt="current blueprint for west side of yard" className="img-responsive" />
                    </div>
                </div>
            </div>
            <Quote/>
        </main>
    );
  };
  
  export default Plan1;