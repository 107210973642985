import { ContactData } from "../types/contactdata";
import { Error } from "../types/error";


// iterate through errors received from server
// for each, parse and call addValidationIssue()
// call showErrorSummary()
const processErrors = (errors: Error[]) => {

  if (errors && errors.length > 0) {
    errors.forEach(processError);
  }

  showErrorSummary();
};


const processError = (value: Error, index: number, array: Error[]) => {
  addValidationIssue(value.fieldId, value.errorMessage, '');
}


// add <li> tag to the validation summary box
// for now, no need to highlight the field
const addValidationIssue = (fieldid: string, errorMessage: string, value: string) => {

  const err = document.createElement("li");
  err.innerText = errorMessage;

  let summary = document.getElementById("vSummary");
  summary?.append(err);

  // highlight the captcha box
  if (fieldid === "frc-captcha") {
    let captchaBox = document.getElementsByClassName(fieldid);
    if (captchaBox.length > 0) {
      captchaBox[0].classList.add("frc-captcha-invalid");
    }
  }
};


const clearFormErrors = (formId: string) => {
  let form = document.getElementById(formId) as HTMLFormElement;
  form.className = "needs-validation";

  // clear captcha validation
  let captchBox = document.getElementsByClassName("frc-captcha");
  if (captchBox.length > 0) {
    captchBox[0].classList.remove("frc-captcha-invalid");
  }

  hideErrorSummary();
};


// show the hidden box
// put the focus on it
const showErrorSummary = () => {
  let summary = document.getElementById("validationSummaryBox");
  if (summary) {
    summary.style.display = "block";
    summary.focus();
  }
};
  

// hide error summary box
// clear contents of summary box
const hideErrorSummary = () => {
  let summaryBox = document.getElementById("validationSummaryBox");
  if (summaryBox) {
    summaryBox.style.display = "none";
    summaryBox.blur();
    let summarylist = summaryBox.querySelector("#vSummary");
    if (summarylist?.firstChild) {
      while(summarylist.firstChild)
        summarylist.removeChild(summarylist.firstChild);
    }
  }
};


const validateCaptcha = (formData: ContactData) => {
  let valid = false;
  let captchaKeyElement = document.getElementsByName("frc-captcha-solution");
  if (captchaKeyElement[0]) {
      let fcsolution = captchaKeyElement[0] as HTMLInputElement;
      formData.frcCaptchaResponse = fcsolution.value;

      if (formData.frcCaptchaResponse === ".UNSTARTED") 
          addValidationIssue("frc-captcha", "Please click the captcha button where it reads 'Click to start verification.'", "");
      else if (formData.frcCaptchaResponse.length > 0)
        valid = true;
      return valid;
  }
};


export {
  processErrors,
  showErrorSummary,
  addValidationIssue,
  clearFormErrors,
  validateCaptcha
};