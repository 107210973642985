import { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom';
import axios, { AxiosError } from "axios";
// import axios, { AxiosError, AxiosResponse } from "axios";
import dayjs from 'dayjs'

import Config from "../../config";

import { Error } from "../../types/error";
import { PhotoDetails } from "../../types/photodetails"
import { PhotoEditOptions } from '../../types/photoeditoptions';

import { useFetchUser, authRoleCheck, useLogout } from "../../hooks/AdminHooks";
import { processErrors, addValidationIssue, showErrorSummary, clearFormErrors } from "../../hooks/ErrorHooks";
import { getPhotoDetails } from "../../hooks/PhotoHooks";

import Header from "../../main/frame/Header";
import PhotoEditCheckbox from './PhotoEditCheckbox';






// React 'uncontrolled' form

// 1. user enters data which calls handleChange() methods
// 2. user clicks submit
// 3. call photoeditValidate()
// 4. call photoEditSend()
// 5. call photoEditReceive()



const PhotoEdit = () =>  {

    const { data: userClaims } = useFetchUser(); // rename 'data' to userClaims to avoid naming conflicts when you have more than one 'useQuery'
    const admin = userClaims ? authRoleCheck(userClaims) : null;

    const params = useParams();
    const strPhotoId = params.photoId ? params.photoId : "0";
    const photoId = Number.parseInt(strPhotoId);

    const [formStatus, setFormStatus] = useState('SEND')

    const { data: cbxlists, isSuccess: cbxFetched } = useQuery<PhotoEditOptions, AxiosError>({
                                                    queryKey: ["photoeditcbxopts"]
                                                    , queryFn: () => axios.post(`${Config.baseApiUrl}/photoopts`).then((resp) => resp.data)
                                                    //, queryFn: () => getPhotoEditCheckBoxOptions()
                                                    //, staleTime: 3600000
                                                });

    const { data: phDetails } = useQuery<PhotoDetails, AxiosError>({
        queryKey: ["photodetails_" + strPhotoId]
        // , queryFn: () => axios.post(`${Config.baseApiUrl}/photoedit/` + params.photoId).then((resp) => resp.data)
        , queryFn: () => getPhotoDetails(photoId)
        , enabled: cbxFetched
        //, staleTime: 3600000
    });

    
    const applyCheckboxValues = (prefix: string, items: number[]) => {
        // use prefix to get parent element
        let cbxSelector = "animal_opts";
    
        switch (prefix) {
            case 'pp': cbxSelector = "plant_opts";
                        break;
            case 'pc': cbxSelector = "category_opts";
                        break;
            case 'pk': cbxSelector = "keyword_opts";
                        break;
        }

        // loop through each checkbox
        // if: id is in list, check it
        // else: uncheck it
        let cbxParent = document.getElementById(cbxSelector);
        let cbxs = cbxParent?.querySelectorAll("input[type=checkbox]");

        cbxs?.forEach(input => {
            const cbx = input as HTMLInputElement;
            const v = Number.parseInt(cbx.value);
            if (items.includes(v))
                cbx.checked = true;
            else
                cbx.checked = false;
        })
    }
    
    
    // apply checkbox values 
    if (phDetails) {

        if (phDetails.animals) 
            applyCheckboxValues("pa", phDetails.animals);
        
        if (phDetails.plants) 
            applyCheckboxValues("pp", phDetails.plants);
        
        if (phDetails.categories) 
            applyCheckboxValues("pc", phDetails.categories);
    
        if (phDetails.keywords) 
            applyCheckboxValues("pk", phDetails.keywords);
    }

    
    const photoeditValidate = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        clearFormErrors("form_photoedit");
        setFormStatus('Validating...');
        
        // validate main form
        let formValid = false;
        let form = document.getElementById("form_photoedit") as HTMLFormElement;

        if (form)
            formValid = form.checkValidity();
        else
            addValidationIssue("", "PhotoUpdate validation failed.", "");

        if (formValid) {
            let phDataToSend = collectFormValues(form);
            photoEditSend(phDataToSend);
        }
        else {
            console.log("form invalid");
            form.className = "was-validated"; // display error(s)
            setFormStatus("SEND"); // reset submit button
            addValidationIssue("", "PhotoUpdate validation failed.", "");
            peValidateFields(form);
            showErrorSummary();
        }
    }


    const peValidateFields = (form: HTMLFormElement) => {
        const pid = form.querySelector('#phPhotoId') as HTMLInputElement;
        if (!pid.checkValidity())
            addValidationIssue("", "Please enter a photo id.", "");
        const hgt = form.querySelector("#phHeight") as HTMLInputElement;
        if (!hgt.checkValidity())
            addValidationIssue("", "Please enter a height.", "");
        const wdth = form.querySelector("#phWidth") as HTMLInputElement;
        if (!wdth.checkValidity())
            addValidationIssue("", "Please enter a width.", "");
        const filename = form.querySelector("#phFileName") as HTMLInputElement;
        if (!filename.checkValidity())
            addValidationIssue("", "Please enter a filename.", "");
        const filetype = form.querySelector("#phFileType") as HTMLInputElement;
        if (!filetype.checkValidity())
            addValidationIssue("", "Please enter a filetype.", "");
        const filesize = form.querySelector("#phFileSize") as HTMLInputElement;
        if (!filesize.checkValidity())
            addValidationIssue("", "Please enter a filesize.", "");
        const alt = form.querySelector("#phAlt") as HTMLInputElement;
        if (!alt.checkValidity())
            addValidationIssue("", "For the Alt field, max length is 500.", "");
        const caption = form.querySelector("#phCaption") as HTMLInputElement;
        if (!caption.checkValidity())
            addValidationIssue("", "For the Caption field, max length is 500.", "");
    }


    const collectFormValues = (form : HTMLFormElement) => {

        // let form = document.getElementById("form_photoedit") as HTMLFormElement;
        let formData = new FormData(form);

        let strAnimalIDs = formData.getAll("pa");
        let animals = strAnimalIDs.map(Number);

        let strPlantIDs = formData.getAll("pp");
        let plants = strPlantIDs.map(Number);
        
        let strCategoryIDs = formData.getAll("pc");
        let categories = strCategoryIDs.map(Number);
        
        let strKeywordIDs = formData.getAll("pk");
        let keywords = strKeywordIDs.map(Number);

        let phDataToSend: PhotoDetails =  {
            photoId: Number.parseInt(formData.get("PhotoId") as string),
            fileName: formData.get("FileName") as string,
            height: Number.parseInt(formData.get("Height") as string),
            width: Number.parseInt(formData.get("Width") as string),
            alt: formData.get("Alt") as string,
            caption: formData.get("Caption") as string,
            fileType: formData.get("FileType") as string,
            fileSize: Number.parseInt(formData.get("FileSize") as string),
            dateTaken: new Date(), // not editable
            dateUploaded: new Date(), // not editable
            animals: animals,
            plants: plants,
            categories: categories,
            keywords: keywords
        };

        return phDataToSend;
    }


    const photoEditSend = (phDataToSend: PhotoDetails) => {
        setFormStatus('Submitting...');

        axios.post(`${Config.baseApiUrl}/photoupdate`, phDataToSend)
                                .then((response) => { photoEditReceive(response.data);});
    }


    const photoEditReceive = (data: Error[]) => {
        console.log("response received!");
        if (data.length > 0) {
            processErrors(data);
            setFormStatus("SEND");
        }
        else {
            // redirect to PhotoIndex
            window.location.href = "/cp/photoindex"; 
        }
    }


    return (
        <main className="main_content_box">
            {userClaims && admin   
            && (
                <div>
                    <div className="container">
                        <Header subtitle="Photo Edit"/>
                        <div className="row">
                            <div className="col-sm-3 detail_link_box">
                                <a href="/cp" rel="noopener noreferrer">
                                    <button id="btn_photo_index" >Admin Home</button>
                                </a>
                            </div>
                            <div className="col-sm-3 detail_link_box">
                                <a href="/cp/photoindex" rel="noopener noreferrer">
                                    <button id="btn_photo_index" >Photo Index</button>
                                </a>
                            </div>
                            <div className="col-sm-3 detail_link_box">
                                <a href="/cp/keywordadd" rel="noopener noreferrer">
                                    <button id="btn_photo_keyword_add">Add Keyword</button>
                                </a>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 text-center photo_edit_banner">
                                <img src={"../../assets/images/_full/" + phDetails?.fileName} alt="@Model.PhotoFile.Alt" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 contact_form_box">
                                <form id="form_photoedit" method="post" onSubmit={e => photoeditValidate(e)} className="needs-validation" noValidate>
                                    <div id="validationSummaryBox">
                                        <div id="validIntro">Please review the below issues.</div>
                                        <ul id="vSummary"></ul>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4 photo_edit_column">
                                            <label>Photo ID:</label>
                                            <input id="phPhotoId" name="PhotoId" type="text" className="photo_edit_field" data-val="true"  defaultValue={phDetails?.photoId} required readOnly/>
                                            <label htmlFor="phHeight">Height</label>
                                            <input id="phHeight" name="Height" type="text" className="photo_edit_field" data-val="true" data-val-number="The field Height must be a number." defaultValue={phDetails?.height} required/>
                                            <label htmlFor="phWidth">Width</label>
                                            <input id="phWidth" name="Width" type="text" className="photo_edit_field" data-val="true" data-val-number="The field Width must be a number." defaultValue={phDetails?.width} required/>
                                        </div>
                                        <div className="col-md-4 photo_edit_column">
                                            <label htmlFor="phFileName">FileName</label>
                                            <input id="phFileName" name="FileName" type="text" className="photo_edit_field" defaultValue={phDetails?.fileName} required/>
                                            <label htmlFor="phFileType">FileType</label>
                                            <input id="phFileType" name="FileType" type="text" className="photo_edit_field" defaultValue={phDetails?.fileType} required/>
                                            <label htmlFor="phFileSize">FileSize</label>
                                            <input id="phFileSize" name="FileSize" type="text" className="photo_edit_field" data-val="true" data-val-number="The field FileSize must be a number." defaultValue={phDetails?.fileSize} required/>
                                        </div>
                                        <div className="col-md-4 photo_edit_column">
                                            <label>DateTaken</label>
                                            <div className="photo_edit_date">
                                                {phDetails && dayjs(phDetails.dateTaken.toString()).format('M/D/YYYY h:mm a')}
                                            </div>
                                            <label>DateUploaded</label>
                                            <div className="photo_edit_date">
                                                {phDetails && dayjs(phDetails.dateUploaded.toString()).format('M/D/YYYY h:mm a')}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <label htmlFor="phAlt">Alt</label>
                                            <input id="phAlt" name="Alt" type="text" className="photo_edit_field photo_edit_txtlong" defaultValue={phDetails?.alt} maxLength={500} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <label htmlFor="phCaption">Caption</label>
                                            <input id="phCaption" name="Caption" type="text" className="photo_edit_field photo_edit_txtlong" defaultValue={phDetails?.caption} maxLength={500} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 photo_edit_cbl_title">
                                            Animals
                                        </div>
                                        <div id="animal_opts" className='photoedit_cbxlist'>
                                            {cbxlists && cbxlists.Animals.map(x => <PhotoEditCheckbox prefix="pa" keyNumber={x.Key.toString()} label={x.Value} key={"pa_" + x.Key}/>)}{/* {...x} = spread syntax to apply all properties */}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 photo_edit_cbl_title">
                                            Plants
                                        </div>
                                        <div id="plant_opts" className='photoedit_cbxlist'>
                                            {cbxlists && cbxlists.Plants.map(x => <PhotoEditCheckbox prefix="pp" keyNumber={x.Key.toString()} label={x.Value} key={"pp_" + x.Key}/>)}{/* {...x} = spread syntax to apply all properties */}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 photo_edit_cbl_title">
                                            Categories
                                        </div>
                                        <div id="category_opts" className='photoedit_cbxlist'>
                                            {cbxlists && cbxlists.Categories.map(x => <PhotoEditCheckbox prefix="pc" keyNumber={x.Key.toString()} label={x.Value} key={"pc_" + x.Key}/>)}{/* {...x} = spread syntax to apply all properties */}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 photo_edit_cbl_title">
                                            Keywords
                                        </div>
                                        <div id="keyword_opts" className='photoedit_cbxlist'>
                                            {cbxlists && cbxlists.Keywords.map(x => <PhotoEditCheckbox prefix="pk" keyNumber={x.Key.toString()} label={x.Value} key={"pk_" + x.Key}/>)}{/* {...x} = spread syntax to apply all properties */}
                                        </div>
                                    </div>
                                
                                    <button id="btn_photoedit_submit" className="btn btn-secondary" type="submit" value="send" disabled={formStatus !== "SEND"}>{formStatus}</button>

                                </form>
                                <div id="form_confirm">
                                    <p>Form submitted.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </main>
    );
}

export default PhotoEdit;