import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { HelmetProvider } from 'react-helmet-async';

import "bootstrap/dist/css/bootstrap.min.css";
import './App.css';

import Navigation from './frame/Navigation';
import Footer from './frame/Footer';
import Home from '../components/home/Home';
import Plan2 from '../components/plans/Plan2';
import Plan1 from '../components/plans/Plan1';
import Resources from '../components/resources/Resources';
import HouseCats from '../components/resources/HouseCats';
import PlantList from '../components/plant/PlantList';
import PlantDetail from '../components/plant/PlantDetail';
import AnimalList from '../components/animal/AnimalList';
import AnimalDetail from '../components/animal/AnimalDetail';
import Photos from '../components/photos/Photos';
import ContactForm from '../components/contact/ContactForm';

import AdminHome from '../components/admin/AdminHome';
import PhotoIndex from '../components/admin/PhotoIndex';
import PhotoEdit from '../components/admin/PhotoEdit';
import KeywordAdd from '../components/admin/KeywordAdd';
import PhotosImport from '../components/admin/PhotosImport';


function App() {

  // useScript('https://use.typekit.net/foobar.js');

  const queryClient = new QueryClient();
    
//     {  defaultOptions: {
//     queries: {
//        staleTime: Infinity,
//     },
//   },
// })


  // const routes = useRoutes([
  //   { path: "/", element: <Home /> },
  //   { path: "/plans/blueprint2", element: <Plan2/>},
  //   { path: "/plans/blueprint1", element: <Plan1/>},
  //   { path: "/resources", element: <Resources/>},
  //   { path: "/cats", element: <HouseCats/>},
  //   { path: "/fauna", element: <AnimalList/>,
  //     children: [
  //       { path: ":id", element: <AnimalDetail />}
  //     ],
  //   }
  //   // {
  //   //   path: "/invoices",
  //   //   element: <Invoices />,
  //   //   children: [
  //   //     { path: ":id", element: <Invoice /> },
  //   //     { path: "/pending", element: <Pending /> },
  //   //     { path: "/complete", element: <Complete /> },
  //   //   ],
  //   // },
  //   // {
  //   //   path: "/users",
  //   //   element: <Users />,
  //   //   children: [
  //   //     { path: ":id", element: <Profile /> },
  //   //     { path: "/settings", element: <Settings /> },
  //     // ],
  //   // },
  // ]);


  return (
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <div>
          <Navigation/>
          {/* {routes} */}
          <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="/plans/blueprint2" element={<Plan2 />}></Route>
            <Route path="/plans/blueprint1" element={<Plan1 />}></Route>
            <Route path="/resources" element={<Resources />}></Route>
            <Route path="/cats" element={<HouseCats />}></Route>
            <Route path="/fauna" element={<AnimalList />}></Route>
            <Route path="/fauna/:keyId" element={<AnimalDetail />}></Route>
            <Route path="/flora" element={<PlantList />}></Route>
            <Route path="/flora/:keyId" element={<PlantDetail />}></Route>
            <Route path="/photos" element={<Photos />}></Route>
            <Route path="/contact" element={<ContactForm />}></Route>
            {/* admin pages */}
            <Route path="/cp" element={<AdminHome />}></Route>
            <Route path="/cp/photoindex" element={<PhotoIndex />}></Route>
            <Route path="/cp/photoedit/:photoId" element={<PhotoEdit />}></Route>
            <Route path="/cp/keywordadd" element={<KeywordAdd />}></Route>
            <Route path="/cp/photosimport" element={<PhotosImport />}></Route>
          </Routes>
          <Footer/>
        </div>
        <ReactQueryDevtools />
      </QueryClientProvider>
    </HelmetProvider>
  );
}

export default App;
