import { useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import { useNavigate } from "react-router-dom";
import axios from "axios";

import Config from "../../config";

import { Error } from "../../types/error";

import { useFetchUser, authRoleCheck, useLogout } from "../../hooks/AdminHooks";
import { processErrors, showErrorSummary, clearFormErrors } from "../../hooks/ErrorHooks";

import Header from "../../main/frame/Header";



const KeywordAdd = () =>  {

    const { data: userClaims } = useFetchUser(); // rename 'data' to userClaims to avoid naming conflicts when you have more than one 'useQuery'
    const admin = userClaims ? authRoleCheck(userClaims) : null;

    const [formStatus, setFormStatus] = useState('SAVE')
    const [keyword, setKeyword] = useState({keyword: ""})

    // const urlBack = document.referrer;
    const navigate = useNavigate();
    // navigate.call()

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { id, value } = event.target;
        setKeyword((formData) => ({ ...formData, [id]: value }));
    };



    const keywordValidate = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        clearFormErrors("form_keyword");
        setFormStatus('Validating...');
        
        // validate main form
        let formValid = false;
        let form = document.getElementById("form_keyword") as HTMLFormElement;

        if (form)
            formValid = form.checkValidity();

        if (formValid)
            keywordSave();
        else {
            console.log("form invalid");
            form.className = "was-validated"; // display error(s)
            setFormStatus("SAVE"); // reset submit button
            showErrorSummary();
        }
    };


    const keywordSave = () => {
        setFormStatus('Submitting...');
        mutation.mutate();
    };  


    const keywordReceive = (data: Error[]) => {
        console.log("response received!");
        if (data.length > 0) {
            processErrors(data);
            setFormStatus("SAVE");
        }
        else {
            let form = document.getElementById("form_keyword") as HTMLFormElement;
            let thanks = document.getElementById("form_confirm") as HTMLElement;
            form.style.display = "none";
            thanks.style.display = "block";
        }
    };


    const mutation = useMutation({
        mutationFn: () => {
            return axios.post(`${Config.baseApiUrl}/keywordadd`, keyword)
        },
        onError: (error, variables, context) => {
            console.log(error.message); // todo: replace this
            // display generic error message in error summary box
        },
        onSuccess: (data, variables, context) => {
            let errors = data.data as Error[];
            keywordReceive(errors);
        },
        onSettled: (data, error, variables, context) => {
            console.log("onSettled() called..."); 
        }
    });


    let goBack = () => {
        navigate(-1);
      };


    return (
        <div className="main_content_box">
            {userClaims && admin   
            && (
                <div>
                    <div className="container">
                        <Header subtitle="Add Keyword"/>
                        <div className="row">
                            <div className="col-sm-3 detail_link_box">
                                <a href="/cp" rel="noopener noreferrer">
                                    <button id="btn_admin_home" >Admin Home</button>
                                </a>
                            </div>
                            <div className="col-sm-3 detail_link_box">
                                <a href="/cp/photoindex" rel="noopener noreferrer">
                                    <button id="btn_photo_index" >Photo Index</button>
                                </a>
                            </div>
                            <div className="col-sm-3 detail_link_box">
                                <button id="btn_photo_keyword_add" onClick={()=>goBack()}>Go Back</button>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 contact_form_box">
                                <form id="form_keyword" method="post" onSubmit={e => keywordValidate(e)} className="needs-validation" style={{position: "relative"}} noValidate>
                                    <div id="validationSummaryBox">
                                        <div id="validIntro">Please review the below issues.</div>
                                        <ul id="vSummary"></ul>
                                    </div>
                                    <label>
                                        <input id="keyword" maxLength={50} onChange={handleChange} className="form-control" required/>
                                        <div className="invalid-tooltip">Please enter a keyword.</div>
                                    </label>
                                    <button id="btn_keyword_submit" className="btn btn-secondary" type="submit" value="save" disabled={formStatus !== "SAVE"}>{formStatus}</button>
                                </form>
                                <div id="form_confirm">
                                    <p>Keyword submitted successfully.</p>
                                    <div className="col-sm-6 detail_link_box">
                                        <a href="/cp/keywordadd" rel="noopener noreferrer">
                                            <button id="btn_add_keyword" >Add Another Keyword</button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )} 
        </div>
    );

};


export default KeywordAdd;