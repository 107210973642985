
type Args = {
  subtitle: string;
};

const Header = ({ subtitle }: Args) => {

  return (

    <header>
      <div className="row">
            <div className="col-md-12 main_content_title">{subtitle}</div>
      </div>
    </header>

  );
};

export default Header;
