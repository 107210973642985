import { useFetchQuote } from "../../hooks/QuoteHooks";


const Quote = () => {

    const {data} = useFetchQuote();

    if (!data) 
        return <div className="quote_box"></div>; // <div>Quote not found.</div>;

    return (
        <div className="quote_box">
            <div className="container">
                <div className="row">
                    <div className="quote_text">
                        {data.quotation}
                    </div>
                </div>
                <div className="row">
                    <div className="quote_src">
                        {data.author}
                    </div>
                </div>
                <div className="row">
                    <div className="quote_src">
                        {data.citation}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Quote;
