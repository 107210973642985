import { Photo } from "../../types/photo"


const PhotoLink = ({...p}: Photo) =>  {

    const title = `Photo ID: ${p.photoId}`;
    const cssClass = (p.height > p.width) ? "ph_thumb_portrait" : "ph_thumb_landscape";
    const url = "/cp/photoedit/" + p.photoId.toString();

    return (
        <a href={url} title={title} data-description={p.caption} className="photo_thumb " data-download-url='false'>
            <img src={p.thumbFileName} alt={p.alt} className={cssClass}/>
        </a>
    );
}

export default PhotoLink;