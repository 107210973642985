import axios, { AxiosError } from "axios";
import { useQuery } from "@tanstack/react-query";

import Config from "../config";

import { Quote } from "./../types/quote";


const useFetchQuote = () => {

    // generate random between 1 and 17
    const quoteid = randomIntFromInterval(1, 17);

    return useQuery<Quote, AxiosError>({ 
        // queryKey: ["quote_" + quoteid.toString()],
        queryKey: ["quote"],
        // staleTime: 3600000,
        queryFn: async () => 
                    axios.get(`${Config.baseApiUrl}/quote/${quoteid}`).then((resp) => resp.data)
    });
  };


  const randomIntFromInterval = (min: number, max: number) => {  // min and max included 
    const quoteid = Math.floor(Math.random() * (max - min + 1) + min);
    return quoteid;
  }


  export {
    useFetchQuote
  };