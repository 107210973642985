import Carousel from "react-bootstrap/Carousel"


function HomeCarousel() {
    return (
      <Carousel fade>
        <Carousel.Item>
            <img src="./assets/images/home/carousel/home_carousel_frog.jpg" className="d-block w-100" alt="gray tree frog sitting on woodland sunflower leaf" />
        </Carousel.Item>
        <Carousel.Item>
            <img src="./assets/images/home/carousel/home_carousel_moth.jpg" className="d-block w-100" alt="polyphemus moth sitting at base of birch tree" />
        </Carousel.Item>
        <Carousel.Item>
            <img src="./assets/images/home/carousel/home_carousel_chickadee.jpg" className="d-block w-100" alt="chickadee checking out nest box" />
        </Carousel.Item>
        <Carousel.Item>
            <img src="./assets/images/home/carousel/home_carousel_cardinal.jpg" className="d-block w-100" alt="red cardinal perched in tree in winter" />
        </Carousel.Item>
        <Carousel.Item>
            <img src="./assets/images/home/carousel/home_carousel_rusty.jpg" className="d-block w-100" alt="possible rusty patch bumblebee feeding on joe pye weed" />
        </Carousel.Item>
        <Carousel.Item>
            <img src="./assets/images/home/carousel/home_carousel_myrtle.jpg" className="d-block w-100" alt="phoebe sitting on broken branch" />
        </Carousel.Item>
        <Carousel.Item>
            <img src="./assets/images/home/carousel/home_carousel_bluebird.jpg" className="d-block w-100" alt="bluebird parents picking up worms for their young" />
            {/* <ExampleCarouselImage text="First slide" /> */}
            {/* <Carousel.Caption>
              <h3>First slide label</h3>
              <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
            </Carousel.Caption> */}
        </Carousel.Item>
      </Carousel>
    );
}

export default HomeCarousel;
